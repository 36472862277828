import clsx, {type ClassValue} from "clsx"
export {type ClassValue} from "clsx"
import {extendTailwindMerge} from "tailwind-merge"
import {libFontSizes, libShadows} from "tailwind.config"

export const twMergeConfig = {
    extend: {
        classGroups: {
            "font-size": [
                {
                    text: Object.keys(libFontSizes)
                }
            ],
            shadow: [
                {
                    shadow: Object.keys(libShadows)
                }
            ]
        }
    }
}

const customTwMerge = extendTailwindMerge(twMergeConfig)

export function cn(...classes: ClassValue[]) {
    return customTwMerge(clsx(...classes))
}
