import type {Config} from "tailwindcss"
import type {PluginAPI} from "tailwindcss/types/config"
import defaultTheme from "tailwindcss/defaultTheme"
import defaultColor from "tailwindcss/colors"
import tailwindcssAnimate from "tailwindcss-animate"
import plugin from "tailwindcss/plugin"
import flattenColorPalette from "tailwindcss/lib/util/flattenColorPalette"
import toColorValue from "tailwindcss/lib/util/toColorValue"
import typography from "@tailwindcss/typography"

export const libFontSizes = {
    "display-xl": [
        "3rem",
        {
            lineHeight: "3.5rem",
            fontWeight: "520"
        }
    ],
    "display-l": [
        "2rem",
        {
            lineHeight: "2.5rem",
            fontWeight: "550",
            letterSpacing: "-0.02em"
        }
    ],
    "display-m": [
        "1.5rem",
        {
            lineHeight: "2rem",
            fontWeight: "520",
            letterSpacing: "0.01em"
        }
    ],
    "display-s": [
        "1rem",
        {
            lineHeight: "1.5rem",
            fontWeight: "520",
            letterSpacing: "0.01em"
        }
    ],
    "heading-s": [
        "15px",
        {
            lineHeight: "24px",
            fontWeight: "500"
        }
    ],
    "heading-m": [
        "17px",
        {
            lineHeight: "24px",
            fontWeight: "500"
        }
    ],
    "body-s": [
        "14px",
        {
            lineHeight: "20px",
            fontWeight: "400"
        }
    ],
    action: [
        "14px",
        {
            lineHeight: "20px",
            fontWeight: "500"
        }
    ],
    "caption-m": [
        "12px",
        {
            lineHeight: "16px",
            fontWeight: "500"
        }
    ],
    "caption-s": [
        "11px",
        {
            lineHeight: "12px",
            fontWeight: "500"
        }
    ],
    footnote: [
        "12px",
        {
            lineHeight: "16px",
            fontWeight: "400"
        }
    ],
    "landing-label-l": [
        "18px",
        {
            lineHeight: "28px",
            fontWeight: "500",
            letterSpacing: "-0.01em"
        }
    ],
    "landing-paragraph-sm": [
        "14px",
        {
            lineHeight: "20px",
            fontWeight: "400"
        }
    ],
    "landing-paragraph-xs": [
        "12px",
        {
            lineHeight: "16px",
            fontWeight: "400",
            letterSpacing: "-0.006em"
        }
    ],
    "label-xs": [
        "12px",
        {
            lineHeight: "16px",
            fontWeight: "500",
            letterSpacing: "-.006em"
        }
    ],
    "label-lg": [
        "18px",
        {
            lineHeight: "28px",
            fontWeight: "500",
            letterSpacing: "-.0075em"
        }
    ],
    "paragraph-sm": [
        "14px",
        {
            lineHeight: "20px",
            fontWeight: "400"
        }
    ],
    "paragraph-lg": [
        "18px",
        {
            lineHeight: "28px",
            fontWeight: "400",
            letterSpacing: "-.01em"
        }
    ]
} as any

export const libShadows = {
    "primary-button": [
        "0 19px 8px rgba(31, 31, 31, .01)",
        "0 11px 6px rgba(31, 31, 31, .04)",
        "0 5px 5px rgba(31, 31, 31, .07)",
        "0 1px 3px rgba(31, 31, 31, .08)",
        "0 0 0 1px var(--shadow-primary-button-color, #08771E)",
        "0 0.5px 1px rgba(255, 255, 255, .82) inset"
    ],
    "toggle-active": ["0 6px 6px -3px rgba(14, 18, 27, .06)", "0 3px 3px -1.5px rgba(14, 18, 27, .06)", "0 0.5px 1px rgba(14, 18, 27, .03)"],
    avatar: "0 -4px 4px theme(colors.primary.normal / 0.16) inset",
    action: ["0 2px 4px #A3A3A30F", "0 -.5px .5px #A3A3A314 inset"],
    "testimonial-card": [
        "0 0 0 1px rgba(51, 51, 51, .08)",
        "0 1px 1px -.5px rgba(51, 51, 51, .05)",
        "0 3px 3px -1.5px rgba(51, 51, 51, .05)",
        "0 6px 6px -3px rgba(51, 51, 51, .05)",
        "0 12px 12px -6px rgba(51, 51, 51, .05)",
        "0 24px 24px -12px rgba(51, 51, 51, .05)",
        "0 120px 120px -80px rgba(161, 161, 170, .04)"
    ],
    "nav-button-hover": ["0 19px 8px #1f1f1f03", "0 11px 6px #1f1f1f0a", "0 5px 5px #1f1f1f12", "0 1px 3px #1f1f1f14", "0 0 0 1px #1f1f1f", "inset 0 -0.5px 1px rgba(255, 255, 255, 0.82)"],
    "title-inner-box": ["0 0 0 1px rgba(0, 0, 0, 0.12)", "inset 0 0.5px 1px rgba(255, 255, 255, 0.82)"],
    "smart-way-item": ["0 19px 8px rgba(31, 31, 31, .01)", "0 11px 6px rgba(31, 31, 31, .04)", "0 5px 5px rgba(31, 31, 31, .07)", "0 1px 3px rgba(31, 31, 31, .08)", "0 0 0 1px #1A1A1A", "inset 0 .5px .5px rgba(255, 255, 255, .12)"],
    "accordion-item": ["0 0 0 1px rgba(51, 51, 51, .08)", "0 4px 8px rgba(82, 82, 82, .06)", "0 2px 4px rgba(82, 82, 82, .04)", "0 1px 2px rgba(82, 82, 82, .04)", "inset 0 -.5px .5px rgba(61, 61, 61, .08)"],
    "platform-actions-container": [
        "0 0 0 1px rgba(51, 51, 51, 0.08)",
        "0 1px 1px -0.5px rgba(51, 51, 51, 0.05)",
        "0 3px 3px -1.5px rgba(51, 51, 51, 0.05)",
        "0 6px 6px -3px rgba(51, 51, 51, 0.05)",
        "0 12px 12px -6px rgba(51, 51, 51, 0.05)",
        "0 24px 24px -12px rgba(51, 51, 51, 0.05)"
    ]
} as any

const createFullBleedPlugin = () =>
    plugin(({matchUtilities, addUtilities, theme}) => {
        addUtilities({
            ".bleed-bg": {
                "border-image": "linear-gradient(var(--tw-full-bleed-color) 0 0) fill 0/ /0 9999vw 0 9999vw"
            },
            ".bleed-bg-r": {
                "border-image": "linear-gradient(var(--tw-full-bleed-color) 0 0) fill 0/ /0 9999vw 0 0"
            },
            ".bleed-bg-l": {
                "border-image": "linear-gradient(var(--tw-full-bleed-color) 0 0) fill 0/ /0 0 0 9999vw"
            },
            ".bleed-none": {
                "--tw-full-bleed-border-top-width": "0px",
                "--tw-full-bleed-border-bottom-width": "0px",
                "border-image": "none"
            }
        })

        matchUtilities(
            {
                bleed: (value) => ({
                    "--tw-full-bleed-color": toColorValue(value)
                })
            },
            {
                values: flattenColorPalette({
                    ...theme("colors"),
                    ...theme("bleedColors")
                }),
                type: ["color"]
            }
        )

        const bleedBorderUtilityVariations = [
            [
                "bleed-border",
                [
                    "--tw-full-bleed-border-top-width",
                    "--tw-full-bleed-border-bottom-width",
                    ["border-image", "linear-gradient(var(--tw-full-bleed-color) 0 0) 1/ /0 9999vw 0 9999vw"],
                    ["border-top", "var(--tw-full-bleed-border-top-width) solid"],
                    ["border-bottom", "var(--tw-full-bleed-border-bottom-width) solid"]
                ]
            ],
            [
                ["bleed-border-t", ["--tw-full-bleed-border-top-width", ["border-image", "linear-gradient(var(--tw-full-bleed-color) 0 0) 1/ /0 9999vw 0 9999vw"], ["border-top", "var(--tw-full-bleed-border-top-width) solid"]]],
                ["bleed-border-b", ["--tw-full-bleed-border-bottom-width", ["border-image", "linear-gradient(var(--tw-full-bleed-color) 0 0) 1/ /0 9999vw 0 9999vw"], ["border-bottom", "var(--tw-full-bleed-border-bottom-width) solid"]]],
                [
                    "bleed-border-r",
                    [
                        "--tw-full-bleed-border-top-width",
                        "--tw-full-bleed-border-bottom-width",
                        ["border-image", "linear-gradient(var(--tw-full-bleed-color) 0 0) 1/ /0 9999vw 0 0"],
                        ["border-top", "var(--tw-full-bleed-border-top-width) solid"],
                        ["border-bottom", "var(--tw-full-bleed-border-bottom-width) solid"]
                    ]
                ],
                [
                    "bleed-border-l",
                    [
                        "--tw-full-bleed-border-top-width",
                        "--tw-full-bleed-border-bottom-width",
                        ["border-image", "linear-gradient(var(--tw-full-bleed-color) 0 0) 1/ /0 0 0 9999vw"],
                        ["border-top", "var(--tw-full-bleed-border-top-width) solid"],
                        ["border-bottom", "var(--tw-full-bleed-border-bottom-width) solid"]
                    ]
                ],
                ["bleed-border-tl", ["--tw-full-bleed-border-top-width", ["border-image", "linear-gradient(var(--tw-full-bleed-color) 0 0) 1/ /0 0 0 9999vw"], ["border-top", "var(--tw-full-bleed-border-top-width) solid"]]],
                ["bleed-border-tr", ["--tw-full-bleed-border-top-width", ["border-image", "linear-gradient(var(--tw-full-bleed-color) 0 0) 1/ /0 9999vw 0 0"], ["border-top", "var(--tw-full-bleed-border-top-width) solid"]]],
                ["bleed-border-bl", ["--tw-full-bleed-border-bottom-width", ["border-image", "linear-gradient(var(--tw-full-bleed-color) 0 0) 1/ /0 0 0 9999vw"], ["border-bottom", "var(--tw-full-bleed-border-bottom-width) solid"]]],
                ["bleed-border-br", ["--tw-full-bleed-border-bottom-width", ["border-image", "linear-gradient(var(--tw-full-bleed-color) 0 0) 1/ /0 9999vw 0 0"], ["border-bottom", "var(--tw-full-bleed-border-bottom-width) solid"]]]
            ]
        ]

        const createUtilityPlugin = (themeKey: string, utilityVariations: any[][], options: {values: any; type: string[]}) => {
            utilityVariations.forEach((variationGroup) => {
                const groups = Array.isArray(variationGroup[0]) ? variationGroup : [variationGroup]

                groups.forEach(([classPrefix, properties]) => {
                    matchUtilities(
                        {
                            [classPrefix]: (value: string) =>
                                properties.reduce((acc: any, prop: any) => {
                                    if (Array.isArray(prop)) {
                                        return {...acc, [prop[0]]: prop[1]}
                                    }
                                    return {...acc, [prop]: value}
                                }, {})
                        },
                        //@ts-ignore
                        options
                    )
                })
            })
        }

        createUtilityPlugin("bleedBorderWidth", bleedBorderUtilityVariations, {
            values: {
                ...theme("borderWidth"),
                ...theme("bleedBorderWidth")
            },
            type: ["length"]
        })
    })

const config: Config = {
    darkMode: "class",
    mode: "jit",
    content: [
        "./pages/**/*.{js,ts,jsx,tsx,mdx}",
        "./components/**/*.{js,ts,jsx,tsx,mdx}",
        "./app/**/*.{js,ts,jsx,tsx,mdx}",
        "./utils/**/*.{js,ts,jsx,tsx,mdx}",
        "./src/**/*.{js,ts,jsx,tsx,mdx}",
        "./src/*",
        "./src/**/*",
        "./src/components/*.{js,ts,jsx,tsx}",
        "node_modules/flowbite-react/**/*.{js,jsx,ts,tsx}",
        "node_modules/@tremor/**/*.{js,ts,jsx,tsx}"
    ],
    theme: {
        colors: {
            ...defaultColor,
            destructive: "#EF3A2F",
            neutral: {
                0: "#FFFFFF",
                100: "#F5F5F5",
                200: "#E0E0E0",
                300: "#CCCCCC",
                400: "#A3A3A3",
                500: "#8F8F8F",
                600: "#7A7A7A",
                700: "#525252",
                800: "#292929",
                900: "#0F0F0F"
            },
            primary: {
                lighter: "#F2FDF4",
                light: "#CAF6D3",
                normal: "#289F40",
                dark: "#00330A"
            },
            orange: {
                ...defaultColor.orange,
                lighter: "#FEF0E6",
                light: "#FCC39C",
                normal: "#ED6408"
            },
            purple: {
                lighter: "#EDE3FC",
                light: "#C1A2F6",
                normal: "#7C3AED"
            },
            red: {
                ...defaultColor.red,
                lighter: "#FEEDEC",
                light: "#FCDBD9",
                normal: "#EF3A2F",
                dark: "#97140C"
            },
            blue: {
                ...defaultColor.blue,
                lighter: "#ECF4FD",
                normal: "#3189EC"
            },
            dashbg: "#f6f6f9",
            "tiktok-red": "#EA3355",
            "tiktok-blue": "#6FEEE9",
            "tiktok-blue-dark": "#16B6B0",
            tremor: {
                brand: {
                    faint: "#eff6ff",
                    muted: "#bfdbfe",
                    subtle: "#60a5fa",
                    DEFAULT: "#3b82f6",
                    emphasis: "#1d4ed8",
                    inverted: "#ffffff"
                },
                background: {
                    muted: "#f9fafb",
                    subtle: "#f6f6f9",
                    DEFAULT: "#ffffff",
                    emphasis: "#374151"
                },
                border: {
                    DEFAULT: "#e5e7eb"
                },
                ring: {
                    DEFAULT: "#e5e7eb"
                },
                content: {
                    subtle: "#9ca3af",
                    DEFAULT: "#6b7280",
                    emphasis: "#374151",
                    strong: "#111827",
                    inverted: "#ffffff"
                }
            }
        },
        extend: {
            fontFamily: {
                sans: ["var(--font-sans)"],
                dashboard: ["var(--font-dashboard)", ...defaultTheme.fontFamily.sans]
            },
            fontWeight: {
                550: "550"
            },
            fontSize: {
                ...libFontSizes,
                "tremor-label": ["0.75rem"],
                "tremor-default": ["0.875rem", {lineHeight: "1.25rem"}],
                "tremor-title": ["1.125rem", {lineHeight: "1.75rem"}],
                "tremor-metric": ["1.875rem", {lineHeight: "2.25rem"}]
            },
            boxShadow: {
                ...libShadows,
                "tremor-input": "0 1px 2px 0 rgb(0 0 0 / 0.05)",
                "tremor-card": "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
                "tremor-dropdown": "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
                "dark-tremor-input": "0 1px 2px 0 rgb(0 0 0 / 0.05)",
                "dark-tremor-card": "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
                "dark-tremor-dropdown": "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
                "keesy-card": "0px 0px 0px 1px rgba(225, 226, 228, 0.56), 0px 1px 2px 0px rgba(119, 124, 133, 0.12)"
            },
            borderRadius: {
                "tremor-small": "0.375rem",
                "tremor-default": "0.5rem",
                "tremor-full": "9999px",
                "4xl": "2rem"
            },
            zIndex: {
                100: "100"
            },
            transitionDuration: {
                400: "400ms",
                600: "600ms"
            },
            transitionDelay: {
                400: "400ms",
                600: "600ms"
            },
            animationDelay: {
                2000: "2s"
            },
            transitionTimingFunction: {
                ease: "ease"
            },
            animation: {
                shimmer: "shimmer 5s linear infinite forwards",
                ring: "ring 3s calc(var(--i) * 1s) linear infinite",
                spin: "spin 12s linear infinite",
                spinRadar: "spinRadar 8s linear infinite",
                "accordion-down": "accordion-down 0.2s ease-out",
                "accordion-up": "accordion-up 0.2s ease-out",
                "fade-in": "fade-in 0.5s linear forwards",
                "fade-in-delayed": "fade-in 0.8s linear 1.3s forwards",
                marquee: "marquee var(--marquee-duration) linear infinite",
                gradient: "gradient 6s ease infinite",
                "spin-slow": "spin 4s linear infinite",
                "spin-slower": "spin 6s linear infinite",
                "spin-reverse": "spin-reverse 1s linear infinite",
                "spin-reverse-slow": "spin-reverse 4s linear infinite",
                "spin-reverse-slower": "spin-reverse 6s linear infinite",
                "infinite-scroll": "infinite-scroll 30s linear infinite",
                slide: "slide 3.5s linear infinite",
                "slide-delayed": "slide-delayed 6s infinite",
                "ping-button": "ping-button 2.7s cubic-bezier(0, 0, 0.2, 1) infinite",
                "fade-in-up-short": "fade-in-up-short 0.3s ease-out",
                "fade-in-up-bounce": "fade-in-up-bounce 0.3s ease-out",
                "fade-out-down-bounce": "fade-out-down-bounce 0.3s ease-in",
                sway: "sway 0.6s ease-out 3s",
                "text-gradient": "text-gradient 6s ease infinite alternate",
                "background-shine": "background-shine 2s linear infinite",
                "badge-rotate": "badge-rotate 4s linear infinite",
                "border-beam": "border-beam calc(var(--duration) * 1s) infinite linear",
                heroSpark: "heroSpark 6s ease infinite both",
                slideDown: "slideDown .6s cubic-bezier(0.16, 1, 0.3, 1)",
                slideUp: "slideUp .6s cubic-bezier(0.16, 1, 0.3, 1)",
                shake: "shake 0.82s cubic-bezier(.36,.07,.19,.97) both",
                glow: "glow 3s ease-in-out infinite"
            },
            minWidth: {
                xl: "36rem"
            },
            keyframes: {
                shimmer: {
                    "0%": {
                        left: "-700%"
                    },
                    "100%": {
                        left: "800%"
                    }
                },
                ring: {
                    "0%": {
                        width: "4px",
                        height: "4px"
                    },
                    "100%": {
                        opacity: "0",
                        width: "22px",
                        height: "22px"
                    }
                },
                spin: {
                    to: {
                        transform: "rotate(360deg)"
                    }
                },
                spinRadar: {
                    from: {
                        transform: "rotate(-210deg)"
                    },
                    to: {
                        transform: "rotate(30deg)"
                    }
                },
                "accordion-down": {
                    from: {height: "0"},
                    to: {height: "var(--radix-accordion-content-height)"}
                },
                "accordion-up": {
                    from: {height: "var(--radix-accordion-content-height)"},
                    to: {height: "0"}
                },
                "fade-in": {
                    from: {opacity: "0"},
                    to: {opacity: "1"}
                },
                marquee: {
                    "100%": {transform: "translateY(-50%)"}
                },
                "spin-reverse": {
                    to: {transform: "rotate(-360deg)"}
                },
                gradient: {
                    "0%": {backgroundPosition: "0% 50%"},
                    "50%": {backgroundPosition: "100% 50%"},
                    "100%": {backgroundPosition: "0% 50%"}
                },
                "infinite-scroll": {
                    from: {transform: "translateX(0)"},
                    to: {transform: "translateX(calc(-250px * 5))"}
                },
                slide: {
                    "0%": {transform: "translateY(100%)", opacity: "0.1"},
                    "15%": {transform: "translateY(0)", opacity: "1"},
                    "30%": {transform: "translateY(0)", opacity: "1"},
                    "45%": {transform: "translateY(-100%)", opacity: "1"},
                    "100%": {transform: "translateY(-100%)", opacity: "0.1"}
                },
                "slide-delayed": {
                    "0%, 100%": {transform: "translateY(100%)", opacity: "0.1"},
                    "15%": {transform: "translateY(0)", opacity: "1"},
                    "30%": {transform: "translateY(0)", opacity: "1"},
                    "45%": {transform: "translateY(-100%)", opacity: "1"},
                    "50%, 100%": {transform: "translateY(-100%)", opacity: "0.1"}
                },
                "ping-button": {
                    "75%, 100%": {transform: "scaleX(1.3) scaleY(1.5)", opacity: "0"}
                },
                "fade-in-up-short": {
                    "0%": {opacity: "0", transform: "translateY(1rem)"},
                    "100%": {opacity: "1", transform: "translateY(0)"}
                },
                "fade-in-up-bounce": {
                    "0%": {opacity: "0", transform: "translateY(2rem)"},
                    "75%": {opacity: "1", transform: "translateY(-0.5rem)"},
                    "100%": {opacity: "1", transform: "translateY(0)"}
                },
                "fade-out-down-bounce": {
                    "0%": {opacity: "1", transform: "translateY(0)"},
                    "25%": {opacity: "1", transform: "translateY(-0.5rem)"},
                    "100%": {opacity: "0", transform: "translateY(2rem)"}
                },
                sway: {
                    "0%": {transform: "rotate(0deg)"},
                    "50%": {transform: "rotate(15deg)"},
                    "100%": {transform: "rotate(0deg)"}
                },
                "text-gradient": {
                    "0%": {backgroundPosition: "0% 50%"},
                    "50%": {backgroundPosition: "100% 50%"},
                    "100%": {backgroundPosition: "0% 50%"}
                },
                "background-shine": {
                    from: {backgroundPosition: "0 0"},
                    to: {backgroundPosition: "-200% 0"}
                },
                "border-beam": {
                    "100%": {
                        "offset-distance": "-100%"
                    }
                },
                heroSpark: {
                    "0%": {transform: "translateY(-100%)"},
                    "100%": {transform: "translateY(1000px)"}
                },
                slideDown: {
                    from: {height: "0px"},
                    to: {height: "var(--radix-accordion-content-height)"}
                },
                slideUp: {
                    from: {height: "var(--radix-accordion-content-height)"},
                    to: {height: "0px"}
                },
                shake: {
                    "10%, 90%": {
                        transform: "translate3d(-1px, 0, 0)"
                    },
                    "20%, 80%": {
                        transform: "translate3d(2px, 0, 0)"
                    },
                    "30%, 50%, 70%": {
                        transform: "translate3d(-4px, 0, 0)"
                    },
                    "40%, 60%": {
                        transform: "translate3d(4px, 0, 0)"
                    }
                },
                glow: {
                    "0%, 100%": {
                        boxShadow: "0 0 5px rgba(40, 159, 64, 0.5), 0 0 10px rgba(40, 159, 64, 0.3)"
                    },
                    "50%": {
                        boxShadow: "0 0 18px rgba(40, 159, 64, 0.8), 0 0 24px rgba(40, 159, 64, 0.5)"
                    }
                }
            },
            textShadow: {
                sm: "0 1px 2px rgba(0, 0, 0, 0.1)",
                DEFAULT: "0px 1px 2px rgba(0, 0, 0, 0.1)",
                lg: "0 8px 16px rgba(0, 0, 0, 0.1)"
            },
            typography: {
                DEFAULT: {
                    css: {
                        color: "#000",
                        h1: {
                            margin: 0,
                            paddingTop: "2.5rem", // py-10
                            paddingBottom: "2.5rem",
                            fontSize: "3rem", // text-5xl
                            fontWeight: "700" // font-bold
                        },
                        h2: {
                            margin: 0,
                            paddingTop: "2.5rem", // py-10
                            // paddingBottom: '2.5rem',
                            fontSize: ["1.85rem", {sm: "2.25rem"}], // text-4xl
                            lineHeight: ["2.3rem", {sm: "2.5rem"}],
                            fontWeight: "700", // font-bold
                            display: "inline-block",
                            borderBottom: "5px solid",
                            borderImage: "linear-gradient(to right, var(--color-primary-normal), transparent) 1",
                            paddingBottom: "0.35rem",
                            marginBottom: "1.75rem !important"
                        },
                        h3: {
                            margin: 0,
                            paddingTop: "1.25rem", // py-5
                            paddingBottom: "1.25rem",
                            fontSize: "1.875rem", // text-3xl
                            lineHeight: "2.25rem",
                            fontWeight: "700" // font-bold
                        },
                        h4: {
                            margin: 0,
                            paddingTop: "1.25rem", // py-5
                            paddingBottom: "1.25rem",
                            fontSize: "1.5rem", // text-2xl
                            fontWeight: "700" // font-bold
                        },
                        blockquote: {
                            borderLeftWidth: "4px",
                            borderLeftColor: "#F7AB0A",
                            marginTop: "1.25rem",
                            marginBottom: "1.25rem",
                            paddingTop: "0",
                            paddingLeft: "1.25rem", // pl-5
                            "&::before": {
                                content: "none"
                            },
                            "&::after": {
                                content: "none"
                            }
                        },
                        ul: {
                            paddingTop: "0rem",
                            paddingBottom: "0rem",
                            marginLeft: {
                                DEFAULT: "0rem",
                                sm: "1rem"
                            },
                            marginTop: "1rem",
                            marginBottom: "1rem",
                            listStyleType: "disc",
                            li: {
                                marginTop: "1.1rem",
                                marginBottom: "1.1rem",
                                "&:first-child": {
                                    marginTop: 0
                                }
                            }
                        },
                        ol: {
                            listStyleType: "decimal",
                            marginTop: "1.25rem",
                            li: {
                                marginTop: "1.25rem",
                                marginBottom: "1.25rem",
                                "&:first-child": {
                                    marginTop: 0
                                }
                            }
                        },
                        a: {
                            textDecoration: "underline",
                            textDecorationColor: "#F7AB0A",
                            fontWeight: "inherit",
                            "&:hover": {
                                textDecorationColor: "#000"
                            }
                        },
                        img: {
                            margin: "2.5rem auto", // m-10
                            width: "100%",
                            height: "24rem", // h-96
                            objectFit: "contain"
                        },
                        "blockquote p:first-of-type::before": {
                            content: "none"
                        },
                        "blockquote p:last-of-type::after": {
                            content: "none"
                        }
                    }
                }
            }
        }
    },
    safelist: [
        {
            pattern: /^(bg-(?:slate|gray|zinc|neutral|stone|red|orange|amber|yellow|lime|green|emerald|teal|cyan|sky|blue|indigo|violet|purple|fuchsia|pink|rose)-(?:50|100|200|300|400|500|600|700|800|900|950))$/,
            variants: ["hover", "ui-selected"]
        },
        {
            pattern: /^(text-(?:slate|gray|zinc|neutral|stone|red|orange|amber|yellow|lime|green|emerald|teal|cyan|sky|blue|indigo|violet|purple|fuchsia|pink|rose)-(?:50|100|200|300|400|500|600|700|800|900|950))$/,
            variants: ["hover", "ui-selected"]
        },
        {
            pattern: /^(border-(?:slate|gray|zinc|neutral|stone|red|orange|amber|yellow|lime|green|emerald|teal|cyan|sky|blue|indigo|violet|purple|fuchsia|pink|rose)-(?:50|100|200|300|400|500|600|700|800|900|950))$/,
            variants: ["hover", "ui-selected"]
        },
        {
            pattern: /^(ring-(?:slate|gray|zinc|neutral|stone|red|orange|amber|yellow|lime|green|emerald|teal|cyan|sky|blue|indigo|violet|purple|fuchsia|pink|rose)-(?:50|100|200|300|400|500|600|700|800|900|950))$/
        },
        {
            pattern: /^(stroke-(?:slate|gray|zinc|neutral|stone|red|orange|amber|yellow|lime|green|emerald|teal|cyan|sky|blue|indigo|violet|purple|fuchsia|pink|rose)-(?:50|100|200|300|400|500|600|700|800|900|950))$/
        },
        {
            pattern: /^(fill-(?:slate|gray|zinc|neutral|stone|red|orange|amber|yellow|lime|green|emerald|teal|cyan|sky|blue|indigo|violet|purple|fuchsia|pink|rose)-(?:50|100|200|300|400|500|600|700|800|900|950))$/
        }
    ],
    corePlugins: {
        container: false
    },
    plugins: [
        typography,
        tailwindcssAnimate,
        createFullBleedPlugin(),
        ({addComponents}: PluginAPI) => {
            addComponents({
                ".container": {
                    "@apply mx-auto w-full max-w-3xl lg:max-w-[1168px] px-5": ""
                }
            })
        },
        // Plugin to expose CSS variables for colors (e.g. --color-indigo-500)
        function ({addBase, theme}) {
            function extractColorVars(colorObj, colorGroup = "") {
                return Object.keys(colorObj).reduce((vars, colorKey) => {
                    const value = colorObj[colorKey]
                    const cssVariable = colorKey === "DEFAULT" ? `--color${colorGroup}` : `--color${colorGroup}-${colorKey}`

                    const newVars = typeof value === "string" ? {[cssVariable]: value} : extractColorVars(value, `-${colorKey}`)

                    return {...vars, ...newVars}
                }, {})
            }

            addBase({
                ":root": extractColorVars(theme("colors")),
                "::selection": {
                    backgroundColor: "#ffefe9",
                    color: "var(--color-gray-900)"
                }
            })
        },
        // Text shadow plugin
        plugin(function ({matchUtilities, theme}) {
            matchUtilities(
                {
                    "text-shadow": (value) => ({
                        textShadow: value
                    })
                },
                {values: theme("textShadow")}
            )
        })
    ]
}
export default config
